import { ActionType, getType } from 'typesafe-actions'
import {
    setBuyNowAEMProps,
    setBuyNowCartInfo,
    setBuyNowModalOpened,
    clearBuyNowCartInfo,
    setBuyNowInitPaymentInfo,
    setBuyNowInitCheckoutInfo,
} from '../actionCreators'
import * as actions from '../actionCreators/buyNowModal.actionCreators'
import { AEMPropsAndFlags, BuyNowCartData } from '../models/buyNowModal.interface'
import { AddProductToCartGuestResponseDTO, CartSummaryDTO, FilteredCartData } from '../models/cart.interface'
import { InitPayment } from '../models/checkout.interface'

type Action = ActionType<typeof actions>
export interface BuyNowModalDataState {
    aemPropsAndFlags: AEMPropsAndFlags
    guid: string
    cartCode?: string
    isBuyNowModalOpened: boolean
    cart?: BuyNowCartData
    initCheckoutInfo?: FilteredCartData | null
    initPaymentInfo?: InitPayment | null
}

export const initialState: BuyNowModalDataState = {
    aemPropsAndFlags: {} as AEMPropsAndFlags,
    guid: '',
    cartCode: '',
    isBuyNowModalOpened: false,
}

/**
 * @param state
 * @param action
 */
export const buyNowModalDataReducer = (
    state: BuyNowModalDataState = initialState,
    action: Action,
): BuyNowModalDataState => {
    switch (action.type) {
        case getType(setBuyNowAEMProps): {
            return {
                ...state,
                aemPropsAndFlags: action.payload,
            }
        }
        case getType(setBuyNowCartInfo): {
            return getBuyNowCartInfo(state, action.payload as AddProductToCartGuestResponseDTO)
        }
        case getType(setBuyNowInitPaymentInfo): {
            return { ...state, initPaymentInfo: action.payload as InitPayment }
        }
        case getType(setBuyNowInitCheckoutInfo): {
            return { ...state, initCheckoutInfo: action.payload }
        }
        case getType(clearBuyNowCartInfo): {
            return {
                ...state,
                guid: '',
                cartCode: '',
                initCheckoutInfo: null,
                initPaymentInfo: null,
            }
        }
        case getType(setBuyNowModalOpened): {
            return {
                ...state,
                isBuyNowModalOpened: action.payload,
            }
        }
        default:
            return state
    }
}

/**
 *
 * @param state
 * @param payload
 */
const getBuyNowCartInfo = (
    state: BuyNowModalDataState,
    payload: AddProductToCartGuestResponseDTO,
): BuyNowModalDataState => {
    const { guid, cartCode, cartSummary, paymentInfo } = payload
    const { absoluteTotal, itemsDiscount, itemsTotal, taxLines, totalWithTaxesAmt } = cartSummary as CartSummaryDTO

    return {
        ...state,
        guid,
        cartCode,
        cart: {
            cartSummary: {
                absoluteTotal,
                itemsDiscount,
                itemsTotal,
                taxLines,
                totalWithTaxesAmt,
            },
            paymentInfo,
        },
        isBuyNowModalOpened: true,
    }
}
