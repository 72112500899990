import { BrandingColors } from '@nl/lib'
import { frenchLocale } from '@nl/lib/src/globalConstants/global.constant'
import { dateFormats } from '@nl/lib/src/utils'
import getLanguage from '@nl/lib/src/utils/getLanguage'

export const formFieldSize = {
    mini: 'mini',
    small: 'small',
    medium: 'medium',
    large: 'large',
    default: 'default',
}

export const queryParameters = {
    language: 'lang',
    searchQuery: 'q',
    divider: ';',
    count: 'count',
    page: 'page',
    priceUpperBound: 'priceUpperBound',
    priceLowerBound: 'priceLowerBound',
    storeId: 'storeId',
    plpCDSDivider: '&',
    cartId: 'cartId',
    appointmentType: 'appointmentType',
    storeAddressPhone: 'storeAddressPhone',
    errorCode: 'errorCode',
} as const

type queryParametersWithoutDividerType = Omit<typeof queryParameters, 'divider'>

export type queryParametersKeyType = queryParametersWithoutDividerType[keyof queryParametersWithoutDividerType]

export const wishlistErrorObject = {
    maxLimit: 'WishlistOverLimitError',
}

export const accountsPageUrlPattern = {
    en: '/my-account/',
    fr: '/mon-compte/',
}

export const flyerPageUrlPattern = {
    en: '/flyer.html',
    fr: '/circulaires.html',
}

export const componentType = {
    default: 'default',
    triangleSelect: 'triangle-select',
}

export const status = {
    success: 'Success',
    failed: 'Failed',
}

export const EMAIL_MAX_LENGTH = 254
export const EMAIL_MAX_LENGTH_STORE_PICK_UP = 256
export const PHONE_MAX_LENGTH = 12
export const PHONE_WITH_MASKING_MAX_LENGTH = 17
export const PHONE_WITH_MASKING_CONTRY_CODE = '+1 '
export const LAST_NAME_MAXLENGTH = 50
export const FIRST_NAME_MAXLENGTH = 50
export const ADDRESS_MAXLENGTH = 40
export const POSTAL_CODE_MAXLENGTH = 7
export const transactionNavLink = 'triangle-rewards-transaction'
export const transactionNavLinkFr = 'transactions-de-recompenses-triangle'
export const EMAIL_ACQUISITION_SIGNUP_STICKY_BANNER = 'Email Acquisition Sticky SignUp Banner'
export const TIME_TO_CHECK_THE_CURRENT_PATH_CHANGE = 500

// constants for SearchTips component
export const TIPS_HEADLINE_INDEX = 0
export const TIPS_BEGAIN_INDEX = 1
export const TIPS_LAST_ITEM_QUANTITY_FOR_LINK_BULDING = 2
export const TIPS_HREF_LINK_INDEX = 1
export const TIPS_HREF_LABEL_INDEX = 0

// just a class-marker for sticky to bottom elements
export const STICKY_TO_BOTTOM = 'sticky-to-bottom-elem'
export const STICKY_TOC_ON = 'on'

export enum storageData {
    cartGuid = 'cart-guid',
    sflGuid = 'sfl-guid',
    wishlistGuid = 'wishlist-guid',
    wishlistCache = 'wishlist-cache',
    gigyaJWT = 'gigya.JWT',
    preferredStoreId = 'preferred-store-id',
    cartStoreChanged = 'cartStoreChanged',
    isDeliveryModeConflict = 'isDeliveryModeConflict',
    storeTimezone = 'preferred-store-time-zone',
    cartData = 'cartItemsData',
    postalCode = 'postalCode',
    countries = 'countries-data',
    recentSearchList = 'CTC.recentSearchList',
    vehicles = 'vehicles',
    tiresSizeData = 'tiresSizeData',
    medalliaStoreData = 'medalliaStoreData',
    addedNewVehicleInPackage = 'addedNewVehicleInPackage',
    userId = 'userId',
    UID = 'UID',
    regToken = 'regToken',
    tokenState = 'tokenState',
    forgotEmail = 'forgotEmail',
    cdsToken = 'cds.token',
    ctfsMember = 'ctfsMember',
    deliveryPostalCode = 'deliveryPostalCode',
    backToResultLink = 'backToResultLink',
    semafoneMode = 'semafoneMode',
    totalTraingleTransaction = 'totalTriangleTransaction',
    optimizelyShoppingCart = 'optimizelyShoppingCart',
    orderStatus = 'orderStatus',
    miniCartData = 'miniCartData',
    cartMergeFlag = 'cartMergeFlag',
    isGiftCardWasApplied = 'isGiftCardApplied',
    isPaypalTendersApplied = 'isPaypalTendersApplied',
    moveToSFLItem = 'moveToSFLItem',
    emailStickyBannerTimer = 'emailStickyBannerTimer',
    cartLastVisitedDate = 'cartLastVisitedDate',
    webSessionId = 'webSessionId',
    sessionId = 'sessionId',
    timeStamp = 'timeStamp',
    gigyaId = 'gigyaId',
    loyaltyId = 'loyaltyId',
    ttlForCartAndWishlistCache = 'ttlForCartAndWishlistCache',
    isPLACarouselShown = 'isPLACarouselRendered',
    disableWidgetMaxAge = 'disableWidgetMaxAge',
    isSignInForSalesAlerts = 'isSignInForSalesAlerts',
    lmsId = 'loyId',
    miniCartPromoData = 'miniCartPromoData',
    PostalCodeEligibilityForCurrentDeliveryMode = 'PostalCodeEligibilityForCurrentDeliveryMode',
    hasAutoBuyNow = 'hasAutoBuyNow',
    buyNowCartDataRequest = 'buyNowCartDataRequest',
    buyNowProductData = 'buyNowProductData',
}

/**
 * This can be used to support multi languages label
 */
export enum multiLanguageLabel {
    englishLangLabel = 'EN',
    frenchLangLabel = 'FR',
}

export enum multiLanguageAltValue {
    englishLang = 'en_CA',
    frenchLang = 'fr_CA',
}

/**
 * this file contains constants which are used across the app
 */
export const currencyShortForm = 'CA' // currency short form
export const currencyFormat = 'CAD' // currency format
export const digitsPattern = /[^0-9]/g // Regular expression for digits
export const googleMapsUrl = 'http://maps.google.com?q=' // google map url
export const countryCode = 'ca'
export const chevronDown = 'ct-chevron-down'
export const chevronUp = 'ct-chevron-up'
export enum signoutLocation {
    account = 'my account',
    header = 'header',
}
export const regionsData = 'regions-data-'
export const monthList = [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
]

export const CTAClickedName = {
    mainCTAClickedName: 'MAIN',
    stickyCTAClickedName: 'STICKY',
    fbtCTAClickedName: 'FBT',
    wishListCTAClickedName: 'WISHLIST',
}

export const BuyNowClickedName = {
    mainBuyNowClickedName: 'MAIN',
    stickyBuyNowClickedName: 'STICKY',
}

export enum VehicleFitType {
    Fit = 'Fit',
    PartialFit = 'PartialFit',
    NotFit = 'NotFit',
    Unknown = 'UnKnown',
}

export const userType = {
    current: 'current',
    anonymous: 'anonymous',
}

export enum userLoginStatus {
    fullyAuthenticatedUser = 'AUTHORIZED',
    partiallyAuthenticatedUser = 'REMEMBER_ME',
    guestUser = 'ANONYMOUS',
}

export const autoServiceInstallationName = 'AUTO'

export enum productWheelTypes {
    Tire = 'TIRE',
    Wheel = 'WHEEL',
    WinterTire = 'WINTER_TIRE',
    TiresAndWheels = 'TIRES_AND_WHEELS',
}

export enum eProductStatus {
    Active = 'ACTIVE (ACT)',
    Available = 'AVAILABLE (AVA)',
    FullDiscontinued = 'FULLY_DISCONTINUED (FD)',
    WhenOutDiscontinued = 'DISCONTINUED_WHEN_OUT (DW)',
    SeasonalDiscontinued = 'SEASONALLY_DISCONTINUED (SD)',
    TempDiscontinued = 'TEMPORARY_DISCONTINUED (TD)',
    OutOfStock = 'OUT_OF_STOCK (OOS)',
    SeasonalOutOfStock = 'SEASONAL_OUT_OF_STOCK (SOS)',
    TempOutOfStock = 'TEMPORARY_OUT_OF_STOCK (TOS)',
    Discontinued = 'DISCONTINUED (DIS)',
}

export enum dropdownSizes {
    mini = 'mini',
    small = 'small',
    default = 'default',
    medium = 'medium',
}

export enum FitmentTypeEnum {
    UNIVERSAL_FIT_01 = '01_UNIVERSAL_FIT',
    DIRECT_FIT_02 = '02_DIRECT_FIT',
    VEH_COND_FIT_03 = '03_VEH_COND_FIT',
    PROD_VEH_COND_FIT_04 = '04_PROD_VEH_COND_FIT',
    PART_COND_FIT_05 = '05_PART_COND_FIT',
}

export const automotiveFitment = [
    FitmentTypeEnum.DIRECT_FIT_02,
    FitmentTypeEnum.VEH_COND_FIT_03,
    FitmentTypeEnum.PROD_VEH_COND_FIT_04,
    FitmentTypeEnum.PART_COND_FIT_05,
] as const

export const universalFitment = [FitmentTypeEnum.UNIVERSAL_FIT_01, null, undefined] as const

export enum AutoPackageKeys {
    nextSelectedContainer = 'nextSelectedContainer',
    currentSelectedContainer = 'currentSelectedContainer',
    changeSelectedLink = 'changeSelectedLink',
    cartFlyoutChangeSelectedLink = 'cartFlyoutChangeSelectedLink',
    tireData = 'tireData',
    wheelData = 'wheelData',
}
export enum ProductTypeList {
    SERVICE = 'STANDALONE (SER_STA)',
    PRODUCT = 'PRODUCT (PRO)',
    SERVICE_ADDON = 'SERVICE_ADDON (SER_ADD)',
}

export enum TireWheelFitmentNote {
    OEfront = 'OE-front',
    OErear = 'OE-rear',
    OE = 'OE',
    PlusMinus = 'plus-minus',
    OIfront = 'OI-front',
    OIrear = 'OI-rear',
    IsFrontTire = 'isFrontTire',
    OI = 'OI',
    REAR = 'rear',
    FRONT = 'front',
}

export enum TireWheelFitmentTitle {
    staggered = 'STAGGERED',
    regular = 'REGULAR',
}

export const waitTime = 1000

export const flyerStoreId = 'flyerStoreId'

export const currentPageNumber = 1
export const errorCodePrefix = 'error'

export const httpStatusCodes = {
    successCode: 200,
    partialSuccessCode: 206,
    redirectCode: 301,
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403,
    notFound: 404,
    gone: 410,
    internalServer: 500,
    serviceUnavailable: 503,
} as const

export const excludeHttpStatusCodes: number[] = [httpStatusCodes.partialSuccessCode]

export const dateOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
}

// hideSoftRecallModal is added as part of OCCP-20147"
export const hideSoftRecallModal = true

export const specifictionsCode = {
    tireWidth: 'ps_tire_width',
    tireAspectRatio: 'ps_aspect_ratio_percent',
    tireDiameter: 'ps_tirerim_diameter',
    wheelWidth: 'ps_rim_width',
    wheeDiameter: 'ps_wheelrim_diameter',
    vendorGroupId: 'vendor_group_id',
    vendorGroupName: 'vendor_group_name',
} as const

export const countParam = 'count'
export const colorCodeParam = 'colorCode'

export enum StoreChangeSource {
    header = 'HEADER',
    cart = 'CART',
    pdp = 'NEARBY AVAILABILITY MODAL',
    storeLocatorPage = 'STORE LOCATOR PAGE',
    wishlist = 'WISHLIST',
    other = 'OTHER',
}

export const LESS = 'LESS'
export const PERCENT = '[percent]'
export const CUT = '[cut]'
export const versionForMiniCartData = 'v1'

export enum RewardsCardType {
    triangleMasterCard = 'TriangleMC',
    worldEliteMasterCard = 'WorldMC',
    rewardsCard = 'RewardsCard',
    LinkedMC = 'LinkedMC',
}

export enum StoresProvinceList {
    qc = 'QC',
}

export const authenticationClasses = {
    authenticated: 'loggedIn',
    authenticatedRewards: 'loggedIn-rewards',
    authenticatedTSSubscription: 'loggedIn-ts-subscription',
    showSkeleton: 'show-skeleton',
}

export const accessibility = {
    a11yPopUpTitleId: 'popUpTitleId',
    a11yViewOrderHistoryId: 'viewOrderHistoryId',
    a11yTriangleTransactionId: 'triangleTransactionId',
}

export const browseOnlyCookieName = 'odpbrowsemode'

export const triangleUpSellComp = 'triangleUpsell'

export const orderStatus = {
    cancelled: 'CANCELLED',
}

export const fontColor = {
    WHITE: '#ffffff',
    BLACK: '#000000',
}
export const mergeGuestCartEventName = 'mergeGuestCart'
export const isTriangleMastercardLinked = 'Y'

export const triangleMCCard = 'triangleMCCard'
export const triangleSelect = 'triangleSelect'
export const pteGuestTriangleMCCard = 'triangleMasterCardRewards'
export const pteGuestTriangleBaseRewards = 'triangleBaseRewards'
export const rewardBoostCard = 'rewardBoostCard'

export const separatorProps = {
    colors: [BrandingColors.RED, BrandingColors.BURGUNDY, BrandingColors.TEAL],
    style: 'skew',
}

export enum offerTypes {
    MULTIPLIER = 'MULTIPLIER',
    SPENDANDGET = 'SPENDANDGET',
}

export const triangleSelectSeparatorProps = {
    colors: [BrandingColors.WHITE, BrandingColors.GREY, BrandingColors.GREEN],
    style: 'skew',
}

export const expandCardSeparatorProps = {
    colors: [triangleSelectSeparatorProps.colors, separatorProps.colors],
    style: 'skew',
    isColorDynamic: true,
}

export const emptySpace = ''

/** various type of status icons we use for toast */
export const statusIcons = {
    successIcon: 'ct-notification-success-green',
    cautionIcon: 'ct-notification-caution',
    failureIcon: 'ct-toast-error',
    infoIcon: 'ct-information-blue',
}
export const sportCheckBannerId = 'SC'
export const tall = 'tall'
export const tall2 = 'tall2'
export const grid = 'grid'
export const productCardTall = 'product-card-tall'
export const productCardTall2 = 'product-card-tall2'
export const productCard = 'product-card'

/**
 * Enum to differentiate various toast options
 */
export enum ToastVariant {
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
    INFORMATION = 'INFORMATION',
    ERROR = 'ERROR',
}

export enum OnlineSource {
    header = 'online-header',
    footer = 'online-footer',
    checkout = 'online-checkout',
}

export const FRENCH_LANGUAGE = 'fr'

export const returnUrlString = 'returnURL'
export const gclid = 'gclid'
export const google = 'google'
export const utmMedium = 'utm_medium'
export const social = 'social'
export const email = 'Email'

export const easternBanners = ['CTR', 'PTY']

// category carousel breakpoint slide settings
export const categoryCarouselSlidesToShow = {
    MOBILE: 2.5,
    TABLET: 5,
    DESKTOP_SMALL: 7,
    DESKTOP_STANDARD: 8,
    DESKTOP_WIDE: 10,
}

/**
 * Gives the date format for BOPIS ETA based on the current language.
 * @returns {string} The date format.
 */
export const getBOPISDateFormat = (): string => {
    return getLanguage() === frenchLocale ? dateFormats.BOPIS_ETA_FR : dateFormats.BOPIS_ETA_EN
}

export enum Status {
    failed = 'failed',
    success = 'success',
    error = 'error',
    start = 'start',
    reset = 'reset',
}

export const inflationData = {
    heliumIncluded: 'HELIUM_INCLUDED',
    inflationType: 'INFLATION',
    heliumAddOn: 'HELIUM_ADDON',
}

export const balloonEntryType = {
    prePackagedBalloonSku: 'PRE_PACKAGED_BALLOON_SKU',
    preDesignedBalloonBouquet: 'PRE_DESIGNED_BALLOON_BOUQUET',
    weightSku: 'WEIGHT_SKU',
    bagSku: 'BAG_SKU',
    singleBalloonSku: 'SINGLE_BALLOON_SKU',
}

export const CONFIGURABLE_BUNDLE_PACKAGE = 'CONFIGURABLEBUNDLE_PACKAGE'
export const HTTPS_URL_PREFIX = 'https'

export const configurableEntryType = {
    CONFIGURABLEBUNDLE_BALLOONWEIGHT: 'CONFIGURABLEBUNDLE_BALLOONWEIGHT',
    CONFIGURABLEBUNDLE_BALLOONBAG: 'CONFIGURABLEBUNDLE_BALLOONBAG',
    CONFIGURABLEBUNDLE_BALLOONBOUQUET: 'CONFIGURABLEBUNDLE_BALLOONBOUQUET',
}

export const componentList = {
    accounts: {
        addresses: 'addresses',
        notificationPreferences: 'notificationPreferences',
        profile: 'profile',
        security: {
            password: 'password',
            tfaPhoneNumber: 'tfaPhoneNumber',
        },
        mergeLoyaltyCard: 'mergeLoyaltyCard',
        mergeCardForm: 'mergeCardForm',
        shoppingPreferences: 'shoppingPreferences',
        addCreditCard: 'addCreditCard',
        modifyCreditCard: 'modifyCreditCard',
        validateLoyaltyCard: 'validateLoyaltyCard',
    },
    checkout: {
        ctMoney: 'ctMoney',
        shippingInfo: 'shippingInfo',
        billingInfo: 'billingInfo',
        paymentInfo: 'paymentInfo',
    },
    linkCard: {
        linkExistingCard: 'linkExistingCard',
        linkMasterCard: 'linkMasterCard',
        newLoyaltyCard: 'newLoyaltyCard',
        rewardsTermsCondition: 'rewardsTermsCondition',
        linkExistingCardValidate: 'linkExistingCardValidate',
    },
    hpp: {
        addPaymentCard: 'addPaymentCard',
        modifyPaymentCard: 'modifyPaymentCard',
        refreshHppToken: 'refreshHppToken',
    },
    cart: {
        applyInstantCredit: 'applyInstantCredit',
    },
}

/**
 * default paypal options for initializing with CTFS
 * note: height we are setting 55px due to limitation from paypal (allowed values 25 to 55)
 * https://developer.paypal.com/docs/multiparty/checkout/standard/customize/buttons-style-guide/#size
 */
export const defaultPayPalOptions = {
    style: {
        layout: 'vertical',
        color: 'gold',
        shape: 'rect',
        label: 'paypal',
        height: 55,
        disableMaxWidth: true,
        borderRadius: 2,
    },
    buttonContainer: 'payPalCTA',
    reviewOrderPage: true,
}

export const specialSymbols = {
    bullet: '&bull;',
}

export const ORDER_SUMMARY_TOTAL_ID = 'order-summary-total'

export const AEM_CUSTOM_EVENT = 'initReactComponent'

export const SHOW_OFFER_TOUR = 'swapOffersTourCompleted'
