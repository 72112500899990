import { Dispatch } from '@reduxjs/toolkit'
import { RootState } from '../reducers'

import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import {
    userProfileSuccess,
    userProfileFailure,
    signOutSuccessAction,
    signOutErrorAction,
    resetUserProfileAction,
    profileUpdateSuccessAction,
    profileUpdateErrorAction,
    resetProfileUpdateAction,
    setVehicleIdAction,
    resetBaseVehicleIdAction,
    showNoVehicleAction,
    setSoftRecallCTAClickedAction,
    rehydrateUserProfileAction,
    setSelectedVehicleAction,
    setSelectedVehicleIndexAction,
    liteUserProfileCallSuccess,
} from '../actionCreators/user.profile.actionCreators'
import { initOtpFlow, setOtpErrCode } from '../actionCreators/otp.actionCreators'
import {
    UserProfileData,
    SignOutResponseError,
    SoftRecallCTARequestPayload,
    cdsTokenResponse,
    StoredUserProfileData,
} from '../models/user.profile.interface'
import GigyaService from '../../services/gigyaService/gigya.service'
import { GigyaJWTResp, ResponseStatusType } from '../../utils/gigya.type'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import appCacheService from '../../utils/appCacheService'
import { setIsSsoSessionPending } from '../../redux/actionCreators/gigyaScreenSet.actionCreators'
import {
    Vehicle,
    isArrayNotEmpty,
    checkDataLength,
    addClass,
    removeClass,
    isTriangleSelectMember,
    SpinnerTypes,
    isOtpErrorCode,
    OtpErrorType,
} from '@nl/lib'
import { AxiosError } from 'axios'
import { authenticationClasses } from '../../globalConstants/global.constant'
import { handleOffersCacheOnSignOut } from '../../utils/offersCache.helper'
import { logNewRelicError, logNewRelicInfo } from '../../components/NewRelic/newRelic.helper'
import {
    fiveSecondsToTokenExpiration,
    retryMaxAttemptsNumber,
    oneSecondForDelayInterval,
    defaultRetryAttemptValue,
    retryAttemptIncrement,
    baseRetryAttemptDelay,
    retryAttemptsOffset,
} from '../../components/PageInit/GigyaTokenRefresh.constant'
import {
    enableSSSO,
    enableSingleSignOn,
    enableSingleSignOnAsync,
    fromCLP,
    fromCRP,
    profileName,
    ssoLoginHandler,
} from '../../helpers/ciam.helper'
import { dispatchToast, getLoginSuccessToastProps } from '../../components/ToastMessage/ToastMessage.helper'
import { ToastComponentNames } from '../models/toastMessage.interface'
import { ssoLoginAnalyticsEvent, setLoginFromCLPAnalytics } from '../../utils/analytics.util'
import { setProfileBillingSuccess } from '../actionCreators/checkoutDrawer.actionCreators'
import { userProfileData, JWTTokenNoRetryErrorCodes } from '../../components/GigyaScreen/gigya.constants'
import { redirectToLoginPage } from '../../components/PageInit/PageInit.helper'
import { commonContentAvailableSelector } from '../selectors/commonContent.selectors'
import {
    checkIsFirstVisitOrUserDataPresent,
    isValidJson,
    getUserUID,
    shouldUpdateUserAccountData,
    storeUserDataWithExpiry,
    syncReduxStorageValue,
} from '../../helpers/liteProfile.helper'
import { signInBannerVisitedCount } from '../../components/UltraSlimBanner/BannerContentWrapper.constant'
import { setLoyaltyId } from '../../helpers/offers.helper'
import brazeService from '../../services/brazeService/braze.service'
import { setShowSpinner } from '../slices/spinner.slice'
import { getGigyaJwtTokenExpirationStatus, parseGigyaJwtToken } from '../../utils/jwtToken.utils'

const gigyaService = new GigyaService()
const environment = getEnvironment()
const httpClient = getHttpClient()
const bodyEl = document.body

/**
 * Function to add authentication classes to body html element
 * @param {UserProfileData} profileData
 */
const addAuthenticationClasses = (profileData: { data: UserProfileData }) => {
    const isLoading = checkDataLength(profileData.data) === isArrayNotEmpty(profileData.data)
    if (isLoading) {
        addClass(bodyEl, authenticationClasses.showSkeleton)
    } else if (profileData.data.loyalty?.cardNumber) {
        addClass(bodyEl, authenticationClasses.authenticatedRewards)
    } else {
        addClass(bodyEl, authenticationClasses.authenticated)
    }

    if (isTriangleSelectMember(profileData?.data?.loyalty?.tsSubscriptionStatus)) {
        addClass(bodyEl, authenticationClasses.authenticatedTSSubscription)
    }
}

/**
 * Function for handling login failure
 * @param {Dispatch} dispatch,
 * @param {any} error,
 * @param {string} resource,
 */
const handleLoginFailure = (dispatch: Dispatch, resource: string, error?: Error) => {
    appCacheService.gigyaJWTToken.delete()
    localStorageService.removeItem(userProfileData)
    !!appCacheService.lmsId.get() && appCacheService.lmsId.delete()
    dispatch(userProfileFailure())
    error && logNewRelicError({ error, resource })
    if (enableSingleSignOn()) {
        const url = new URL(window.location.href)
        const redirectUrl = url.origin + url.pathname
        !error && ssoLoginHandler(redirectUrl, true, profileName())
    } else {
        !error && redirectToLoginPage(window?.ODP?.globalLinks.loginPageLink)
    }
}

/**
 * Function to initiate SSO log-in sequence calls
 * @param {Dispatch} dispatch dispatch action
 * @param {boolean} isLiteProfileCallRequired boolean values
 * @param {number} expiryTime expiryTime house user stored data
 * @param {boolean} isSSSOEnable is login with SSSO
 */
const loginWithSSO = (
    dispatch: Dispatch,
    isLiteProfileCallRequired: boolean,
    expiryTime: number,
    isSSSOEnable: boolean,
): void => {
    gigyaService
        .verifySession()
        // eslint-disable-next-line consistent-return
        .then((isVerified?: boolean) => {
            if (isVerified) {
                logNewRelicInfo({ logInfo: { hasSession: isVerified }, resource: 'loginWithSSO' })

                dispatch(setShowSpinner({ show: true, type: SpinnerTypes.TRIANGLE }))
                if (!fromCRP()) {
                    fromCLP() ? setLoginFromCLPAnalytics(isVerified) : ssoLoginAnalyticsEvent(isVerified)
                }
                return gigyaService.jwtToken()
            }
        })
        .then((resp?: GigyaJWTResp) => {
            // eslint-disable-next-line camelcase
            const idToken = resp?.id_token
            if (!idToken) throw Error()

            appCacheService.gigyaJWTToken.set(idToken)
            return !isSSSOEnable ? gigyaService.cdsAccessToken(idToken) : {}
        })
        // eslint-disable-next-line consistent-return
        .then((data: { data?: cdsTokenResponse }) => {
            if (data?.data) localStorageService.setItem('remember', String(data.data.rememberMe))
            if (isSSSOEnable || data?.data) {
                return gigyaService.userProfile(appCacheService.gigyaJWTToken.get(), isLiteProfileCallRequired)
            }
        })
        .then((profileData?: { data: UserProfileData }) => {
            if (!profileData) throw Error()

            const userUID = profileData?.data?.UID
            logNewRelicInfo({ logInfo: { hasSession: true }, userUID: userUID, resource: 'loginWithSSO' })
            dispatchToast(true, getLoginSuccessToastProps(), ToastComponentNames.LOGIN_SUCCESS, dispatch)
            dispatch(userProfileSuccess(profileData.data))
            setLoyaltyId(profileData.data)
            !isLiteProfileCallRequired && dispatch(liteUserProfileCallSuccess(profileData.data))
            isLiteProfileCallRequired && storeUserDataWithExpiry(profileData.data, expiryTime)
            addAuthenticationClasses(profileData)
        })
        .catch(error => {
            handleLoginFailure(dispatch, 'loginWithSSO', error)
        })
        .finally(() => {
            dispatch(setShowSpinner({ show: false }))
        })
}

/**
 * Function without checkCookie call for loginWithOutSSO
 * @param {Dispatch} dispatch dispatch function
 * @param {boolean} isLiteProfileCallRequired Param if LiteProfileCall should trigger
 * @param {number} expiryTime expiryTime house user stored data
 * @returns {void}
 */
const loginWithOutSSO = (dispatch: Dispatch, isLiteProfileCallRequired: boolean, expiryTime: number): void => {
    gigyaService
        .jwtToken()
        .then((resp: GigyaJWTResp) => {
            appCacheService.gigyaJWTToken.set(resp.id_token)
            return gigyaService.userProfile(appCacheService.gigyaJWTToken.get(), isLiteProfileCallRequired)
        })
        .then((profileData: { data: UserProfileData }) => {
            dispatch(userProfileSuccess(profileData.data))
            setLoyaltyId(profileData.data)
            !isLiteProfileCallRequired && dispatch(liteUserProfileCallSuccess(profileData.data))
            isLiteProfileCallRequired && storeUserDataWithExpiry(profileData.data, expiryTime)
            addAuthenticationClasses(profileData)
        })
        .catch(error => {
            handleLoginFailure(dispatch, 'loginWithOutSSO', error)
        })
}

// eslint-disable-next-line consistent-return
const retryGetJWTToken = (retryAttemptNum: number, errorCode: string): Promise<string | undefined> => {
    if (!JWTTokenNoRetryErrorCodes.includes(errorCode)) {
        const delayInterval =
            Math.pow(baseRetryAttemptDelay, retryAttemptNum - retryAttemptsOffset) * oneSecondForDelayInterval

        return new Promise(resolve => {
            setTimeout(() => resolve(getJWTToken(retryAttemptNum)), delayInterval)
        })
    }

    return Promise.resolve(undefined)
}

/**
 * Function to get JWT token with retry mechanism
 * @param {number} [retryAttemptNum] - The current retry attempt number
 * @returns {Promise<string | undefined>} - A promise that resolves to the JWT token or undefined
 */
const getJWTToken = (retryAttemptNum?: number): Promise<string | undefined> => {
    return gigyaService
        .jwtToken()
        .then((resp: GigyaJWTResp) => {
            appCacheService.gigyaJWTToken.set(resp.id_token)
            return resp.id_token
        })
        .catch((err: ResponseStatusType): Promise<string | undefined> => {
            const errorCode = err.errorCode?.toString() ?? ''
            const nextRetryAttemptNum = Number(retryAttemptNum || defaultRetryAttemptValue) + retryAttemptIncrement
            const userUID = getUserUID()

            logNewRelicError({
                error: err,
                errorInfo: { retryAttemptNumber: retryAttemptNum || defaultRetryAttemptValue },
                userUID,
                resource: 'getJWTToken',
            })

            return nextRetryAttemptNum > retryMaxAttemptsNumber
                ? Promise.reject(err)
                : retryGetJWTToken(nextRetryAttemptNum, errorCode)
        })
}

/**
 * Function to fetch user profile data
 * @param {boolean} shouldRehydrateUserProfile - Flag to determine if the user profile should be rehydrated.
 * @returns {void}
 */
export const fetchUserProfile =
    (shouldRehydrateUserProfile?: boolean) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const gigyaJWTToken = appCacheService.gigyaJWTToken.get()
        const state = getState()
        const commonContentAvailable = commonContentAvailableSelector(state)
        const {
            // eslint-disable-next-line no-magic-numbers
            accountDashboard: { expiryTime = 24 },
        } = commonContentAvailable
        const userStoredData = localStorageService.getItem(userProfileData)
        const storedUserData =
            userStoredData && isValidJson(userStoredData) ? (JSON.parse(userStoredData) as StoredUserProfileData) : {}
        const isFirstVisitOrUserDataPresent = checkIsFirstVisitOrUserDataPresent(storedUserData)
        const isLiteProfileCallRequired = shouldUpdateUserAccountData(isFirstVisitOrUserDataPresent)

        if (window.gigya) {
            if (!!gigyaJWTToken) {
                const parsedToken = parseGigyaJwtToken(gigyaJWTToken)
                const isTokenAboutToExpireOrExpired = getGigyaJwtTokenExpirationStatus(
                    parsedToken.exp as number,
                    fiveSecondsToTokenExpiration,
                )
                if (enableSingleSignOn() && fromCLP() && isTokenAboutToExpireOrExpired) {
                    // make full profile call
                    loginWithSSO(dispatch, true, expiryTime, enableSSSO())
                } else {
                    const promise = isTokenAboutToExpireOrExpired
                        ? getJWTToken()
                              .then((jwtToken?: string) => {
                                  return jwtToken || ''
                              })
                              .catch(() => {
                                  return Promise.resolve(gigyaJWTToken)
                              })
                        : Promise.resolve(gigyaJWTToken)

                    promise
                        .then(token => {
                            gigyaService
                                .userProfile(token, isLiteProfileCallRequired)
                                .then((profileData: { data: UserProfileData }) => {
                                    const { firstName, email = '' } = profileData.data
                                    if (profileData.data.role === 'ANONYMOUS' && profileData.data.signedIn === false) {
                                        handleLoginFailure(dispatch, 'fetchUserProfile')
                                    } else {
                                        refreshSflGuid(email)
                                        !!firstName && localStorageService.setItem('firstName', firstName)
                                        !!email && localStorageService.setItem('userId', email)
                                        let updatedResponseValue = {}
                                        if (!isLiteProfileCallRequired) {
                                            updatedResponseValue = syncReduxStorageValue(
                                                profileData?.data,
                                                storedUserData,
                                            )
                                        }
                                        dispatch(
                                            userProfileSuccess(
                                                isLiteProfileCallRequired ? profileData.data : updatedResponseValue,
                                            ),
                                        )
                                        setLoyaltyId(profileData.data)
                                        !isLiteProfileCallRequired &&
                                            dispatch(liteUserProfileCallSuccess(profileData.data))
                                        isLiteProfileCallRequired &&
                                            storeUserDataWithExpiry(profileData.data, expiryTime)
                                        shouldRehydrateUserProfile && dispatch(rehydrateUserProfileAction(true))
                                        addAuthenticationClasses(profileData)
                                    }
                                })
                                .catch(error => handleLoginFailure(dispatch, 'fetchUserProfile', error))
                        })
                        .catch(console.error)
                }
            } else {
                addAuthenticationClasses({ data: {} })
                handleOffersCacheOnSignOut()
                enableSingleSignOnAsync()
                    .then((isEnableCLP: boolean): void => {
                        if (isEnableCLP) {
                            // make full profile call
                            loginWithSSO(dispatch, true, expiryTime, enableSSSO())
                        } else {
                            // make full profile call
                            loginWithOutSSO(dispatch, true, expiryTime)
                        }
                    })
                    .catch(() => loginWithOutSSO(dispatch, isLiteProfileCallRequired, expiryTime))
            }
        } else dispatch(setIsSsoSessionPending(true))
    }

/**
 * Function to update the profile
 * @param {UserProfileData} requestPayload
 * @param {boolean} shouldUpdateProfileState
 * @returns {void}
 */
export const updateProfile =
    (requestPayload: UserProfileData, shouldUpdateProfileState: boolean, component: string) =>
    (dispatch: Dispatch): Promise<void> => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsUserProfile}`

        return httpClient
            .apiPut(url, { ...requestPayload }, headers, true)
            .then(data => {
                const successStatus = 200
                const profileUpdated = data.status === successStatus
                if (requestPayload?.primaryBillingAddress) {
                    dispatch(setProfileBillingSuccess(true))
                }
                shouldUpdateProfileState &&
                    dispatch(profileUpdateSuccessAction({ profileUpdated, profileData: requestPayload }))
            })
            .catch((err: AxiosError<SignOutResponseError>) => {
                if (isOtpErrorCode(err.response?.data.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: err.response?.data.vToken as string,
                            component,
                            userID: err.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(err.response?.data.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(err.response?.data.errCode as string))
                } else {
                    dispatch(profileUpdateErrorAction(err.response?.data || ({} as SignOutResponseError)))
                    if (requestPayload?.primaryBillingAddress) {
                        dispatch(setProfileBillingSuccess(false))
                    }
                }
            })
    }

// SignOut currently logged in User
export const signOutUser =
    (location: string) =>
    (dispatch: Dispatch): Promise<void> => {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsSignOut}`
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        return httpClient
            .apiGet(url, {}, headers, true)
            .then(() => {
                sessionStorage.removeItem(signInBannerVisitedCount)
                handleOffersCacheOnSignOut()
                brazeService.destroy()
                dispatch(signOutSuccessAction(location))
                localStorageService.removeItem(userProfileData)
                addAuthenticationClasses({ data: {} })
                removeClass(bodyEl, [
                    authenticationClasses.authenticated,
                    authenticationClasses.authenticatedRewards,
                    authenticationClasses.authenticatedTSSubscription,
                ])
                !!appCacheService.wishlistCache.get() && appCacheService.wishlistCache.delete()
                !!appCacheService.lmsId.get() && appCacheService.lmsId.delete()
            })
            .catch((error: AxiosError<SignOutResponseError>) => {
                const errorResponse = error.response ? error.response.data : error
                dispatch(signOutErrorAction(errorResponse as SignOutResponseError))
            })
    }

export const resetProfileUpdate =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetProfileUpdateAction())
    }

export const resetUserProfileData =
    () =>
    (dispatch: Dispatch): void => {
        localStorageService.removeItem(userProfileData)
        !!appCacheService.lmsId.get() && appCacheService.lmsId.delete()
        dispatch(resetUserProfileAction())
    }

export const showNoVehicleData =
    (vehicleList: Vehicle[]) =>
    (dispatch: Dispatch): void => {
        dispatch(showNoVehicleAction(vehicleList))
    }

export const setVehicleId =
    (vehicle: Record<string, string>) =>
    (dispatch: Dispatch): void => {
        dispatch(setVehicleIdAction(vehicle))
    }

export const resetBaseVehicleId =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetBaseVehicleIdAction())
    }

export const setSoftRecallCTAClicked =
    (requestPayload: SoftRecallCTARequestPayload) =>
    (dispatch: Dispatch): void => {
        dispatch(setSoftRecallCTAClickedAction(requestPayload))
    }

/**
 * Function to remove sfl when diffrent user is logged in Partial auth
 * @param {string} email
 */
export const refreshSflGuid = (email: string) => {
    const previousUserEmail = localStorageService.getItem('userId')
    if (previousUserEmail && previousUserEmail !== email) appCacheService.removeSflGuid()
}

export const setSelectedVehicle =
    (requestPayload: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedVehicleAction(requestPayload))
    }

export const setSelectedVehicleIndex =
    (requestPayload: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedVehicleIndexAction(requestPayload))
    }
