import { ResponseStatusType } from '../../utils/gigya.type'

interface newRelicErrorObj {
    error: Error | ResponseStatusType
    errorInfo?: unknown
    userUID?: string
    resource: string
    logErrorName?: string
}

interface newRelicInfoObj {
    logInfo?: unknown
    userUID?: string
    resource: string
    logInfoName?: string
}

interface logNewRelicRedirectToCLPObj {
    resource: string
    gigyaUID?: string
    errorInfo?: unknown
    cdsAPI?: string
    errorInfoName?: string
}

export const logNewRelicError = ({
    error,
    errorInfo = {},
    userUID,
    resource,
    logErrorName = resource,
}: newRelicErrorObj): void => {
    window.newrelic?.addPageAction?.(`JSTRACE:${logErrorName}`, {
        error,
        errorInfo,
        userUID,
        resource,
    })
}

export const logNewRelicInfo = ({ logInfo = {}, userUID, resource, logInfoName = resource }: newRelicInfoObj): void => {
    window.newrelic?.addPageAction?.(`JSTRACE:${logInfoName}`, { userUID, logInfo, resource })
}

export const logNewRelicRedirectToCLP = ({
    resource,
    gigyaUID = '',
    errorInfo = {},
    cdsAPI = '',
    errorInfoName = resource,
}: logNewRelicRedirectToCLPObj): void => {
    window.newrelic?.addPageAction?.(`JSTRACE:${errorInfoName}`, { gigyaUID, errorInfo, cdsAPI })
}
