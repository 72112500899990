import { createAction } from 'typesafe-actions'

import {
    ProductDataType,
    ProductDataTypeObj,
    productDisplayCountType,
    SortOption,
    CompareProductSpecificationType,
} from '../models/productData.interface'
import { ProductResponseErrorDTO } from '../models/product.interface'
import {
    FETCH_PRODUCT_SUCCESS,
    FETCH_WEATHER_TECH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAILURE,
    FETCH_LOAD_MORE_PRODUCT_SUCCESS,
    UPDATE_FACET_CLICK,
    UPDATE_SELECTED_SORT,
    CLEAR_PRODUCT_LIST,
    FETCH_PRODUCT_REQUEST,
    FETCH_WEATHER_TECH_PRODUCT_REQUEST,
    FILL_PRICE_AVAILABILITY,
    SET_NO_RESULT,
    SET_PRODUCT_DISPLAY_COUNT,
    ADD_PRODUCT_TO_COMPARE_LIST,
    REMOVE_PRODUCT_FROM_COMPARE_LIST,
    CLEAR_PRODUCT_COMPARE_LIST,
    FILL_SKU_PRICES,
    ADD_PRODUCT_TO_SPECIFICATION_LIST,
    REMOVE_PRODUCT_FROM_SPECIFICATION_LIST,
    CLEAR_PRODUCT_SPECIFICATION_LIST,
} from '../types/products/productData.actionTypes.constant'
import { PriceAvailabilityDataResponse } from '../../services/priceAvailabilityService/priceAvailabilityService.type'
import { SkuPriceDataResponse } from '../../services/skuPriceService/skuPriceService.type'

export const fetchProductCardDataRequestAction = createAction(FETCH_PRODUCT_REQUEST)()

export const fetchWeatherTechProductCardDataRequestAction = createAction(FETCH_WEATHER_TECH_PRODUCT_REQUEST)()

export const setNoResult = createAction(SET_NO_RESULT)()
export const setProductDisplayCount = createAction(
    SET_PRODUCT_DISPLAY_COUNT,
    (payload: productDisplayCountType) => payload,
)<productDisplayCountType>()

export const fetchProductCardDataSuccessAction = createAction(
    FETCH_PRODUCT_SUCCESS,
    (payload: ProductDataType) => payload,
)<ProductDataType>()

export const fetchWeatherTechProductCardDataSuccessAction = createAction(
    FETCH_WEATHER_TECH_PRODUCT_SUCCESS,
    (payload: ProductDataType) => payload,
)<ProductDataType>()

export const fetchLoadMoreProductDataSuccessAction = createAction(
    FETCH_LOAD_MORE_PRODUCT_SUCCESS,
    (payload: ProductDataTypeObj[]) => payload,
)<ProductDataTypeObj[]>()

export const fetchProductCardDataErrorAction = createAction(
    FETCH_PRODUCT_FAILURE,
    (payload: ProductResponseErrorDTO) => payload,
)<ProductResponseErrorDTO>()

export const updateFacetClickAction = createAction(UPDATE_FACET_CLICK)<string>()

export const updateSelectedSortAction = createAction(UPDATE_SELECTED_SORT)<SortOption>()

export const clearProductListAction = createAction(CLEAR_PRODUCT_LIST)()

export const fillPriceAndAvailability = createAction(
    FILL_PRICE_AVAILABILITY,
    (payload: PriceAvailabilityDataResponse) => payload,
)()

export const clearProductCompareList = createAction(CLEAR_PRODUCT_COMPARE_LIST)()

export const addProductToCompareList = createAction(
    ADD_PRODUCT_TO_COMPARE_LIST,
    (payload: ProductDataTypeObj) => payload,
)()

export const removeProductFromCompareList = createAction(
    REMOVE_PRODUCT_FROM_COMPARE_LIST,
    (payload: string) => payload,
)()
export const fillSkuPrices = createAction(FILL_SKU_PRICES, (payload: SkuPriceDataResponse) => payload)()
export const addProductToSpecificationList = createAction(
    ADD_PRODUCT_TO_SPECIFICATION_LIST,
    (payload: CompareProductSpecificationType[]) => payload,
)()

export const removeProductFromSpecificationList = createAction(
    REMOVE_PRODUCT_FROM_SPECIFICATION_LIST,
    (payload: string) => payload,
)()

export const clearProductSpecificationList = createAction(CLEAR_PRODUCT_SPECIFICATION_LIST)()
