/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'

import {
    fetchProductCardDataSuccessAction,
    fetchLoadMoreProductDataSuccessAction,
    updateFacetClickAction,
    updateSelectedSortAction,
    clearProductListAction,
    fetchProductCardDataRequestAction,
    fetchWeatherTechProductCardDataSuccessAction,
    fetchWeatherTechProductCardDataRequestAction,
    fetchProductCardDataErrorAction,
    fillPriceAndAvailability,
    fillSkuPrices,
    setNoResult,
    setProductDisplayCount,
    clearProductCompareList,
    addProductToCompareList,
    removeProductFromCompareList,
    addProductToSpecificationList,
    removeProductFromSpecificationList,
    clearProductSpecificationList,
} from '../actionCreators'
import * as actions from '../actionCreators/productData.actionCreators'
import {
    ProductDataType,
    ProductDataTypeObj,
    productDisplayCountType,
    SortOption,
    CompareProductSpecificationType,
} from '../models/productData.interface'
import mergePriceAvailabilityDataWithProducts from '../utils/mergePriceAvailabilityDataWithProducts'
import mergeSkuPriceDataWithProducts from '../utils/mergeSkuPriceDataWithProducts'

type Action = ActionType<typeof actions>

export interface ProductDataState {
    productCardData: ProductDataType
    weatherTechProductCardData: ProductDataType
    loadMoreProductData: ProductDataTypeObj[]
    selectedFacetType: string
    selectedSortOption: SortOption
    productCardLoading: boolean
    weatherTechProductCardLoading: boolean
    errorStatusCode: number | null
    productDisplayCount: productDisplayCountType
    shouldCompareListOpen: boolean
    compareProductsList: ProductDataTypeObj[]
    compareProductsSpecificationList: CompareProductSpecificationType[]
}

export const initialState: ProductDataState = {
    productCardData: {} as unknown as ProductDataType,
    weatherTechProductCardData: {} as unknown as ProductDataType,
    loadMoreProductData: [] as unknown as ProductDataTypeObj[],
    selectedFacetType: '',
    selectedSortOption: {} as SortOption,
    productCardLoading: false,
    weatherTechProductCardLoading: false,
    errorStatusCode: null,
    productDisplayCount: {} as productDisplayCountType,
    shouldCompareListOpen: false,
    compareProductsList: [] as ProductDataTypeObj[],
    compareProductsSpecificationList: [] as unknown as CompareProductSpecificationType[],
}

export const PRODUCT_DATA_STATE_KEY = 'productCardData'

// eslint-disable-next-line sonar/cyclomatic-complexity
export const productDataReducer = (state: ProductDataState = initialState, action: Action): ProductDataState => {
    switch (action.type) {
        case getType(fetchProductCardDataRequestAction): {
            return { ...state, productCardLoading: true }
        }
        case getType(fetchWeatherTechProductCardDataRequestAction): {
            return { ...state, weatherTechProductCardLoading: true }
        }
        case getType(fetchProductCardDataErrorAction): {
            const { status } = action.payload
            return {
                ...state,
                errorStatusCode: status,
                weatherTechProductCardLoading: false,
                productCardLoading: false,
            }
        }
        case getType(fetchProductCardDataSuccessAction): {
            const copyOfStateToMutate: ProductDataState = JSON.parse(JSON.stringify(state)) as ProductDataState

            return { ...copyOfStateToMutate, productCardData: action.payload, productCardLoading: false }
        }
        case getType(setNoResult): {
            return {
                ...state,
                productCardData: { ...state.productCardData, resultCount: 0, products: [], facets: [] },
                productCardLoading: false,
            }
        }
        case getType(fetchWeatherTechProductCardDataSuccessAction): {
            const copyOfStateToMutate: ProductDataState = JSON.parse(JSON.stringify(state)) as ProductDataState

            return {
                ...copyOfStateToMutate,
                weatherTechProductCardData: action.payload,
                weatherTechProductCardLoading: false,
            }
        }
        case getType(fetchLoadMoreProductDataSuccessAction): {
            return { ...state, loadMoreProductData: action.payload }
        }
        case getType(updateFacetClickAction): {
            return { ...state, selectedFacetType: action.payload }
        }
        case getType(updateSelectedSortAction): {
            return {
                ...state,
                selectedSortOption: action.payload,
            }
        }
        case getType(clearProductListAction): {
            return { ...state, productCardData: {} as ProductDataType, loadMoreProductData: [] }
        }
        case getType(fillPriceAndAvailability): {
            return {
                ...state,
                productCardData: {
                    ...state.productCardData,
                    products: mergePriceAvailabilityDataWithProducts(action.payload, state.productCardData.products),
                },
                weatherTechProductCardData: {
                    ...state.weatherTechProductCardData,
                    products: mergePriceAvailabilityDataWithProducts(
                        action.payload,
                        state.weatherTechProductCardData.products,
                    ),
                },
            }
        }
        case getType(setProductDisplayCount): {
            return { ...state, productDisplayCount: action.payload }
        }
        case getType(clearProductCompareList): {
            return {
                ...state,
                shouldCompareListOpen: false,
                compareProductsList: [],
            }
        }
        case getType(addProductToCompareList): {
            const productList = [...state.compareProductsList]
            Array.isArray(action.payload) ? productList.push(...action.payload) : productList.push(action.payload)

            return {
                ...state,
                compareProductsList: [...productList],
            }
        }
        case getType(removeProductFromCompareList): {
            const productsList = [...state.compareProductsList]
            const removeIndex = productsList.findIndex(product => product.code === action.payload)
            productsList.splice(removeIndex, 1)
            return {
                ...state,
                compareProductsList: [...productsList],
            }
        }
        case getType(clearProductSpecificationList): {
            return {
                ...state,
                compareProductsSpecificationList: [],
            }
        }
        case getType(addProductToSpecificationList): {
            return {
                ...state,
                compareProductsSpecificationList: Array.isArray(action.payload) ? action.payload : [action.payload],
            }
        }
        case getType(removeProductFromSpecificationList): {
            const specificationList = [...state.compareProductsSpecificationList]
            const removeIndex = specificationList.findIndex(product => product.code === action.payload)
            specificationList.splice(removeIndex, 1)

            return {
                ...state,
                compareProductsSpecificationList: [...specificationList],
            }
        }
        case getType(fillSkuPrices): {
            return {
                ...state,
                productCardData: {
                    ...state.productCardData,
                    products: mergeSkuPriceDataWithProducts(action.payload, state.productCardData.products),
                },
            }
        }
        default:
            return state
    }
}
