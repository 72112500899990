import { createAction } from 'typesafe-actions'
import {
    SET_BUY_NOW_CART_INFO,
    SET_BUY_NOW_MODAL_AEM_PROPS,
    SET_BUY_NOW_MODAL_OPENED,
    CLEAR_BUY_NOW_CART_INFO,
    SET_BUY_NOW_INIT_PAYMENT_INFO,
    SET_BUY_NOW_INIT_CHECKOUT_INFO,
} from '../types/buyNowModal/buyNowModal.actionTypes.constant'
import { AEMPropsAndFlags } from '../models/buyNowModal.interface'
import { AddProductToCartGuestResponseDTO, FilteredCartData } from '../models/cart.interface'
import { InitPayment } from '../models/checkout.interface'

export const setBuyNowAEMProps = createAction(SET_BUY_NOW_MODAL_AEM_PROPS)<AEMPropsAndFlags>()
export const setBuyNowCartInfo = createAction(SET_BUY_NOW_CART_INFO)<AddProductToCartGuestResponseDTO>()
export const setBuyNowInitPaymentInfo = createAction(SET_BUY_NOW_INIT_PAYMENT_INFO)<InitPayment>()
export const setBuyNowInitCheckoutInfo = createAction(SET_BUY_NOW_INIT_CHECKOUT_INFO)<FilteredCartData>()
export const clearBuyNowCartInfo = createAction(CLEAR_BUY_NOW_CART_INFO)()
export const setBuyNowModalOpened = createAction(SET_BUY_NOW_MODAL_OPENED)<boolean>()
