import BaseService from '../base.service'
import { getHttpClient } from '../../httpClient'
import {
    CartItemsData,
    OrderEntryDTO,
    UpdateDeliveryRequest,
    MiniCartData,
    MiniCartStorageData,
    CheckoutCartItemsData,
    IAddToCartBundleRequest,
    CartPotentialToEarnDTO,
} from '../../redux/models/cart.interface'
import { getEnvironment } from '../../environments'
import appCacheService from '../../utils/appCacheService'
import { AxiosPromise } from 'axios'
import { apiMethods } from '../service.constants'
import { getParamValue, getQueryString } from '@nl/lib'
import { cartguidParam } from './cart.service.constants'
import { FulfillmentMethods } from '../../components/ShoppingCart/ShoppingCart.type'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import { convertToMiniCartData, convertToMiniCartStorageData } from '../../utils/convertToMiniCartData'
import { versionForMiniCartData } from '../../globalConstants/global.constant'
import { getCurrentDateTime } from '../../utils/getDateTime'
import { paymentInformationConst } from '../../components/Checkout/PaymentInformation/cardTypeConstants'
import { isPageForCartEndPoint } from '../../utils/checkPageType'

const environment = getEnvironment()
const httpClient = getHttpClient()
const { checkout } = pageTypes

/**
 * Cart service. All cart related server interaction will be done by this service
 */
class CartService extends BaseService {
    // eslint-disable-next-line no-warning-comments
    // TODO: payload should be created in service

    /**
     * Add new item to the cart
     * @param  {string} guid
     * @param  {OrderEntryDTO[]} orderEntries
     * @param  {string} selectedPreferredStoreId
     * @param  {string} postalCode
     * @param {boolean} isStaggered
     * @param {boolean} isBulk
     * @returns {AxiosPromise}
     */
    addItemToCart(
        guid: string,
        orderEntries: OrderEntryDTO[],
        selectedPreferredStoreId: string,
        postalCode: string,
        isStaggered?: boolean,
        isBulk?: boolean,
        isBuyNow?: boolean,
    ): AxiosPromise {
        const deliveryMode = orderEntries[0].deliveryMode.code.toUpperCase()
        // to check if the added item/items is sth/express or bopis.
        const isSTHOrExpress = deliveryMode === FulfillmentMethods.STH || deliveryMode === FulfillmentMethods.EXPRESS
        const postal = postalCode?.replace(/\s/g, '')
        const url = this.createAddItemUrl(guid, selectedPreferredStoreId, isStaggered, isBulk, isBuyNow).toString()
        // Request body parameter for post api call
        const defaultRequestBody = {
            orderEntries: orderEntries,
        }

        const requestBody =
            isSTHOrExpress && postal ? { ...defaultRequestBody, postalCode: postal } : defaultRequestBody

        return CartService.apiMethod(apiMethods.POST, url, requestBody)
    }

    /**
     * get store id
     * @param  {string} selectedPreferredStoreId
     * @return {string} store id from redux or local storage
     */
    getStoreId(selectedPreferredStoreId?: string): string {
        return selectedPreferredStoreId || appCacheService.preferredStoreId.get()
    }

    /**
     * Create addItem url.
     * @param  {string} guid
     * @param  {string} selectedPreferredStoreId
     * @param {boolean} isStaggered
     * @param {boolean} isBulk
     * @param {boolean} isBuyNow
     * @return {URL}
     */
    createAddItemUrl(
        guid: string,
        selectedPreferredStoreId: string,
        isStaggered?: boolean,
        isBulk?: boolean,
        isBuyNow?: boolean,
    ): URL {
        const {
            API_ENDPOINTS: { addToCartEndpoint },
            baseSiteId,
        } = environment

        const storeName = this.getStoreId(selectedPreferredStoreId)

        const cartGuid = !!guid ? `guid=${guid}` : ''

        const isGrouped = isStaggered ? `&isGrouped=${String(isStaggered)}` : ''

        const isBuyNowParam = isBuyNow ? `&isBuyNow=${String(isBuyNow)}` : ''

        const isBulkParam = isBulk || isBulk === false ? `&isBulk=${String(isBulk)}` : ''

        const isProductAddedFromWishlist = getPageType() === pageTypes.wishlist ? '&isAddedFromWishlist=true' : ''

        return new URL(
            `${environment.API_BASE_URL}${addToCartEndpoint}?${cartGuid}&storeName=${storeName}&lang=${CartService.language}&baseStoreId=${baseSiteId}${isGrouped}${isBulkParam}${isProductAddedFromWishlist}${isBuyNowParam}`,
        )
    }

    /**
     * Function to get guid from passed or from url
     * @param {string} guid - guid passed from action
     * @return {string}
     */
    getGUID(guid?: string): string {
        return guid || getParamValue(window.location.search, cartguidParam, '&') || appCacheService.getCartGuid() || ''
    }

    /**
     * Function returns cart endPoint based on the page type
     * @return {string} returns cart endPoint
     */
    getCartEndPoint(): string {
        const pageType = getPageType()
        if (pageType === checkout) {
            return environment.API_ENDPOINTS.checkoutCartEndpoint
        } else if (isPageForCartEndPoint()) {
            return environment.API_ENDPOINTS.cartEndpoint
        } else {
            return environment.API_ENDPOINTS.miniCartEndpoint
        }
    }

    /**
     * Function to create url for shopping cart
     * @param {string} guid cart guid
     * @param {string} option cart API options
     * @param {string} selectedPreferredStoreId selected store id
     * @param {boolean} isOneTimeCartFlagForAuthUser one time cart flag for auth user
     * @returns {URL} cart enpoint with all params constructed
     */
    createCartDataURL(
        guid: string,
        // eslint-disable-next-line sonar/no-unused-function-argument
        option: string,
        selectedPreferredStoreId: string,
        isOneTimeCartFlagForAuthUser = false,
    ): URL {
        const baseEndPoint = this.getCartEndPoint()
        const guidToPass = !!guid ? `&guid=${this.getGUID(guid)}` : ''
        const isOneTimeCartToPass = isOneTimeCartFlagForAuthUser
            ? `&isOneTimeCart=${String(isOneTimeCartFlagForAuthUser)}`
            : ''
        const refreshCartParam =
            getPageType() === checkout &&
            getQueryString(window.location.search, paymentInformationConst.clickToPaySTHValidationErrorKey) ===
                paymentInformationConst.clickToPaySTHPostalCodeOutOfRangeError
                ? '&refreshCart=false'
                : ''
        return new URL(
            `${environment.API_BASE_URL}${baseEndPoint}?lang=${
                CartService.language
            }${guidToPass}&storeId=${this.getStoreId(
                selectedPreferredStoreId,
            )}${isOneTimeCartToPass}${refreshCartParam}`,
        )
    }

    /**
     * Updates Mini Cart
     * @param {CartItemsData} cartData - cart data
     */
    updateMiniCartData(cartData: CartItemsData): void {
        appCacheService.miniCartData.set(JSON.stringify(convertToMiniCartData(cartData, true)))
    }

    /**
     * Load shopping cart /cart/api/v1/cart
     * @param {string} guid - guid for the user
     * @param {string} option
     * @param {string} selectedPreferredStoreId
     * @param {boolean} isOneTimeCartFlagForAuthUser
     * @return {CartItemsData}
     * TO-DO: to remove option value after actual api integration
     */
    getCartItems(
        guid: string,
        option: string,
        selectedPreferredStoreId: string,
        isOneTimeCartFlagForAuthUser = false,
    ): Promise<{ data: CartItemsData; status: number }> {
        const url: URL = this.createCartDataURL(guid, option, selectedPreferredStoreId, isOneTimeCartFlagForAuthUser)
        return httpClient.apiGet<CartItemsData>(url.toString(), {}).then(cartResponse => {
            this.updateMiniCartData(cartResponse.data)
            return cartResponse
        })
    }

    /**
     * Load shopping cart /api/v1/cart/mini
     * @param {string} guid - guid for the user
     * @param {string} option
     * @param {string} selectedPreferredStoreId
     * @param {boolean} enableCartCache
     * @return {MiniCartData} returns MiniCartData
     */
    getMiniCartItems(
        guid: string,
        option: string,
        selectedPreferredStoreId: string,
        enableCartCache = false,
    ): Promise<{ data: MiniCartData; status: number }> {
        const cachedCartValue = appCacheService.miniCartData.get()
        const now = getCurrentDateTime().getTime()
        if (cachedCartValue && enableCartCache) {
            const data = JSON.parse(cachedCartValue) as MiniCartStorageData
            if (data.expiresIn && now > data.expiresIn) {
                appCacheService.miniCartData.remove()
                appCacheService.miniCartPromoData.remove()
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (data.version === versionForMiniCartData) {
                return Promise.resolve({ data: data, status: 200 })
            }
        }
        const url: URL = this.createCartDataURL(guid, option, selectedPreferredStoreId, false)
        return httpClient.apiGet<MiniCartData>(url.toString(), {}).then(cartItemsResponse => {
            const miniCartStorageData: MiniCartStorageData = convertToMiniCartStorageData(cartItemsResponse.data)
            appCacheService.miniCartData.set(JSON.stringify(miniCartStorageData))
            appCacheService.miniCartPromoData.set(JSON.stringify(cartItemsResponse.data.onSaleItems))
            return cartItemsResponse
        })
    }

    /**
     * Load checkout cart
     * @param {string} guid - guid for the user
     * @param {string} option
     * @param {string} selectedPreferredStoreId
     * @param {boolean} isOneTimeCartFlagForAuthUser
     * @return {CartItemsData}
     * TO-DO: to remove option value after actual api integration
     */
    getCheckoutCartItems(
        guid: string,
        option: string,
        selectedPreferredStoreId: string,
        isOneTimeCartFlagForAuthUser = false,
    ): Promise<{ data: CheckoutCartItemsData; status: number }> {
        const url: URL = this.createCartDataURL(guid, option, selectedPreferredStoreId, isOneTimeCartFlagForAuthUser)
        return httpClient.apiGet<CheckoutCartItemsData>(url.toString(), {}).then(cartResponse => {
            appCacheService.miniCartData.set(JSON.stringify(convertToMiniCartData(cartResponse.data, true)))
            return cartResponse
        })
    }

    /**
     * Function to select JSON for update cart
     * @param {boolean} isRemove
     * @param {string} mockFileName
     * @return {URL}
     */
    selectJSONForUpdateCart(isRemove?: boolean, mockFileName?: string): string {
        const { updateCartMockEndpoint, removeCartMockEndpoint } = environment.API_ENDPOINTS
        return isRemove ? mockFileName || removeCartMockEndpoint : updateCartMockEndpoint
    }

    /**
     * Returns ? or & based on if prev value is available
     * @param {string} guid
     * @param {string} storeId
     * @return {string} guid and store id to be passed in URL
     */
    getGuidAndStoreId(guid?: string, storeId?: string): string {
        const availableGUID = this.getGUID(guid)
        const availableStoreId = this.getStoreId(storeId)
        const guidToPass = !!availableGUID ? `?guid=${availableGUID}` : ''
        const separatorDenom = guidToPass ? '&' : '?'
        const storeIdToPass = !!availableStoreId ? `${separatorDenom}storeId=${availableStoreId}` : ''
        return `${guidToPass}${storeIdToPass}`
    }

    /**
     * Function to create url for update cart quantity
     * @param {string} guid
     * @param {boolean} isRemove
     * @param {string} mockFileName
     * @param {string} storeId
     * @return {URL}
     */
    // eslint-disable-next-line sonar/no-unused-function-argument
    createUpdateCartEntryDataURL(guid: string, isRemove?: boolean, mockFileName?: string, storeId?: string): URL {
        const { updateCartEndpoint } = environment.API_ENDPOINTS

        return new URL(
            `${environment.API_BASE_URL}${updateCartEndpoint}${this.getGuidAndStoreId(guid, storeId)}&lang=${
                CartService.language
            }`,
        )
    }

    /**
     * Function to create url for update cart pickup location
     * @param {string} guid
     * @param {string} storeId
     * @return {URL}
     */
    createUpdateCartDataURL(guid: string, storeId: string): URL {
        const { updateCartPickupLocation } = environment.API_ENDPOINTS

        return new URL(
            `${environment.API_BASE_URL}${updateCartPickupLocation}${this.getGuidAndStoreId(guid, storeId)}&lang=${
                CartService.language
            }`,
        )
    }

    /**
     * Update cart quantity
     * @param {string} guid - guid for the user
     * @param {number} quantity
     * @param {boolean} isRemove
     * @param {string} mockFileName
     * @param {string} storeId
     * @param {number[]} entryNumberArray
     * @param {number} entryNumber
     * @return {AxiosPromise}
     */
    updateCartEntry(
        guid: string,
        quantity?: number,
        isRemove?: boolean,
        mockFileName?: string,
        storeId?: string,
        entryNumberArray?: number[],
    ): AxiosPromise {
        const url = this.createUpdateCartEntryDataURL(guid, isRemove, mockFileName, storeId).toString()
        const apiCall = !isRemove
            ? httpClient.apiPatch(url, { quantity, entryNumbers: entryNumberArray })
            : CartService.apiMethod(apiMethods.DELETE, url, { entryNumbers: entryNumberArray })

        return apiCall.then(cartResponse => {
            appCacheService.miniCartData.set(JSON.stringify(convertToMiniCartData(cartResponse.data, true)))
            return cartResponse
        })
    }

    /**
     * Function for delete bundle from balloon package API call
     * @param {IAddToCartBundleRequest} requestData request body data
     * @param {string} storeId store id
     * @returns {AxiosPromise} AxiosPromise
     */
    deleteItemFromBalloonPackage(requestData: IAddToCartBundleRequest, storeId?: string): AxiosPromise {
        const url: URL = this.createAddToCartBundleUrl(requestData.guid, storeId)
        const requestBody = { entryNumber: requestData.entryNumber, entryGroupNumber: requestData.entryGroupNumber }

        return CartService.apiMethod(apiMethods.DELETE, url.toString(), requestBody).then(cartResponse => {
            appCacheService.miniCartData.set(JSON.stringify(convertToMiniCartData(cartResponse.data, true)))
            return cartResponse
        })
    }

    /**
     * create auto package
     * @param {string} guid - guid for the user
     * @param {string} storeId
     * @param {number[]} entryNumberArray
     * @return {AxiosPromise}
     */
    createAutoPackage(guid: string, storeId?: string, entryNumberArray?: number[]): AxiosPromise {
        const url = this.createUpdateCartEntryDataURL(guid, false, '', storeId).toString()
        return httpClient.apiPatch(url, { entryNumbers: entryNumberArray, isPackageItem: true })
    }

    /**
     * Update cart pickup location
     * @param {string} guid - guid for the user
     * @param {string} storeId
     * @param {number} totalEntries
     * @return {AxiosPromise}
     */
    updateCart(guid: string, storeId: string, totalEntries?: number): AxiosPromise {
        const url = this.createUpdateCartDataURL(guid, storeId).toString()
        const requestPayload = {
            deliveryPointOfService: {
                name: storeId,
            },
            totalEntries: totalEntries,
        }
        return CartService.apiMethod(apiMethods.PUT, url, requestPayload)
    }

    /**
     * Create initCheckout api url based on mock or cds
     * @param {string} guid - guid for guest user
     * @param {string} selectedPreferredStoreId - selected store id
     * @param {boolean} isOneTimeCart - is one time cart
     * @return {URL}
     */
    getInitCheckoutURL(
        guid: string,
        selectedPreferredStoreId: string,
        isOneTimeCart = false,
        buyNowCartCode = '',
    ): URL {
        const availableGuid = this.getGUID(guid)
        const isOneTimeCartToPass = isOneTimeCart ? `&isOneTimeCart=${String(isOneTimeCart)}` : ''
        const hasBuyNowCartCodeParam = buyNowCartCode ? `&cartCode=${String(buyNowCartCode)}` : ''
        return new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.initCheckout}?guid=${availableGuid}&lang=${CartService.language}&storeId=${selectedPreferredStoreId}${isOneTimeCartToPass}${hasBuyNowCartCodeParam}`,
        )
    }

    /**
     * initCheckout Api integration
     * @param {string} guid - guid for the user
     * @param {string} selectedPreferredStoreId
     * @param {boolean} isOneTimeCart - is one time cart
     * @return {CartItemsData}
     */
    initCheckout(
        guid: string,
        selectedPreferredStoreId: string,
        isOneTimeCart = false,
        buyNowCartCode = '',
    ): Promise<{ data: CartItemsData }> {
        const preferredStoreId = this.getStoreId(selectedPreferredStoreId)
        const url: URL = this.getInitCheckoutURL(guid, preferredStoreId, isOneTimeCart, buyNowCartCode)
        return CartService.apiMethod(apiMethods.POST, url.toString())
    }

    /**
     * Create update postal code to cart api url based on mock or cds
     * @param {string} cartGUID - guid for guest user cart
     * @param {string} selectedPreferredStoreId - preferred store id
     * @param {boolean} isOneTimeCart - is one time cart
     * @param {boolean} isBulk - for bulk items in cart
     * @param {string} context - from which page this api is getting called
     * @param {boolean} isExpressDeliveryEligible - express eligibility flag for store
     * @return {string}
     */
    createUpdatePostalCodeCartURL(
        cartGUID: string,
        selectedPreferredStoreId: string,
        // eslint-disable-next-line default-param-last
        isOneTimeCart = false,
        isBulk?: boolean,
        context?: string,
        isExpressDeliveryEligible?: boolean,
    ): string {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { updatePostalCodeToCart },
        } = environment
        const guidToPass = `&guid=${this.getGUID(cartGUID)}`
        const storeIdToPass = `&storeId=${this.getStoreId(selectedPreferredStoreId)}`
        const isOneTimeCartToPass = isOneTimeCart ? `&isOneTimeCart=${String(isOneTimeCart)}` : ''
        const isBulkToPass = isBulk ? `&isBulk=${String(isBulk)}` : ''
        const isContextToPass = !!context ? `&context=${context}` : ''
        const expressDeliveryQueryParam = isExpressDeliveryEligible ? '&isExpressDelivery=true' : ''
        const postalCodeEligibleValue = appCacheService.PostalCodeEligibilityForCurrentDeliveryMode.get()
        const postalCodeEligibleParam =
            postalCodeEligibleValue !== null ? `&postalCodeEligible=${postalCodeEligibleValue}` : ''

        return `${API_BASE_URL}${updatePostalCodeToCart}?lang=${CartService.language}${guidToPass}${storeIdToPass}${isOneTimeCartToPass}${isBulkToPass}${isContextToPass}${expressDeliveryQueryParam}${postalCodeEligibleParam}`
    }

    /**
     * Update postal code to cart api integration
     * @param {string} postalCode - postal code to update
     * @param {string} cartGUID - guid for the cart
     * @param {string} selectedPreferredStoreId - preferred store id
     * @param {boolean} isOneTimeCart - is one time cart
     * @param {boolean} isBulk - for bulk items in cart
     * @param {string} context - from which page this api is getting called
     * @param {boolean} isExpressDeliveryEligible - express eligibility flag for store
     * @return {AxiosPromise}
     */
    updatePostalCodeToCart(
        postalCode: string,
        cartGUID: string,
        selectedPreferredStoreId: string,
        // eslint-disable-next-line default-param-last
        isOneTimeCart = false,
        isBulk?: boolean,
        context?: string,
        isExpressDeliveryEligible?: boolean,
    ): AxiosPromise {
        const url = this.createUpdatePostalCodeCartURL(
            cartGUID,
            selectedPreferredStoreId,
            isOneTimeCart,
            isBulk,
            context,
            isExpressDeliveryEligible,
        )
        const postal = `${postalCode?.replace(/\s/g, '')}`
        // Request body parameter for post api call
        const requestBody = {
            postalCode: postal,
        }
        return CartService.apiMethod(apiMethods.POST, url, requestBody).then(cartResponse => {
            appCacheService.miniCartData.set(JSON.stringify(convertToMiniCartData(cartResponse.data, true)))
            return cartResponse
        })
    }

    /**
     * Create url for shipping estimation
     * @param {string} cartGUID - guid for guest user cart
     * @param {string} storeId - preferred store id
     * @param {boolean} isExpressDeliveryEligible - express eligibility flag for store
     * @return {string}
     */
    createEstimateShippingCostURL(cartGUID: string, storeId: string, isExpressDeliveryEligible: boolean): string {
        const {
            API_ENDPOINTS: { estimateShippingCost },
        } = environment
        const guidToPass = `&guid=${this.getGUID(cartGUID)}`
        const storeIdToPass = `&storeId=${this.getStoreId(storeId)}`
        const expressDeliveryQueryParam = isExpressDeliveryEligible ? '&isExpressDelivery=true' : ''

        return `${environment.API_BASE_URL}${estimateShippingCost}?lang=${CartService.language}${guidToPass}${storeIdToPass}${expressDeliveryQueryParam}`
    }

    /**
     * Estimate Shipping cost API call
     * @param {string} postalCode - postal code to update
     * @param {string} cartGUID - guid for the cart
     * @param {string} storeId - preferred store id
     * @param {boolean} isExpressDeliveryEligible - express eligibility flag for store
     * @return {AxiosPromise}
     */
    estimateShippingCost(
        postalCode: string,
        cartGUID: string,
        storeId: string,
        isExpressDeliveryEligible: boolean,
    ): AxiosPromise {
        const url = this.createEstimateShippingCostURL(cartGUID, storeId, isExpressDeliveryEligible)
        const postal = `${postalCode?.replace(/\s/g, '')}`
        const requestBody = { postalCode: postal }

        return CartService.apiMethod(apiMethods.POST, url, requestBody)
    }

    /**
     * Function to create url for changing Cart delivery mode
     * @param {string} cartId
     * @return {URL}
     */
    private createChangeCartDeliveryModeURL(cartId: string): URL {
        const pathWithQueryParams = environment.API_ENDPOINTS.changeCartDeliveryMode
        const url = CartService.createUri(pathWithQueryParams)
        url.searchParams.append('guid', cartId)
        return url
    }

    /**
     * Function to change Cart delivery mode
     * @param {string} cartId
     * @param {string} deliveryMode
     * @return {Promise}
     */
    changeCartDeliveryMode(cartId: string, deliveryMode: string) {
        const url: URL = this.createChangeCartDeliveryModeURL(cartId)
        const payload = { deliveryModeCode: deliveryMode }

        return CartService.apiMethod(apiMethods.PUT, url.toString(), payload)
    }

    /**
     * Create URL to work with promo code API
     * @param {string} cartId - cart id
     * @param {string} promoCode - promo code
     * @param {string} storeId - preferred store id
     * @return {string}
     */
    createPromoCodeURL(cartId: string, promoCode: string, storeId: string): string {
        const {
            API_ENDPOINTS: { promoCode: promoCodeEndpoint },
        } = environment
        const queryParams = `?guid=${cartId}&voucher=${promoCode}&storeId=${storeId}&lang=${CartService.language}`
        return `${environment.API_BASE_URL}${promoCodeEndpoint}${queryParams}`
    }

    /**
     * Apply promo code to the cart
     * @param {string} cartId - cart id
     * @param {string} promoCode - promo code
     * @param {string} storeId - preferred store id
     * @return {AxiosPromise}
     */
    applyPromoCode(cartId: string, promoCode: string, storeId: string): AxiosPromise {
        const url = this.createPromoCodeURL(cartId, promoCode, storeId)

        return CartService.apiMethod(apiMethods.POST, url)
    }

    /**
     * Delete promo code from the cart
     * @param {string} cartId - cart id
     * @param {string} promoCode - promo code
     * @param {string} storeId - preferred store id
     * @return {AxiosPromise}
     */
    deletePromoCode(cartId: string, promoCode: string, storeId: string): AxiosPromise {
        const url = this.createPromoCodeURL(cartId, promoCode, storeId)

        return CartService.apiMethod(apiMethods.DELETE, url)
    }

    /**
     * remove cart Api integration
     * @param {string} guid - guid for the user
     * @return {AxiosPromise}
     * TO-DO: to remove option value after actual api integration
     */
    deleteCart(guid: string): AxiosPromise {
        const path = environment.API_ENDPOINTS.deleteCart.replace('{cartId}', guid)
        const url = CartService.createUri(path)
        return httpClient.apiDelete(url.toString())
    }

    /**
     * Function to change fulfillment option.
     * @param {string} type - to which fulfillment you want to change the item to.
     * @param {string} guid
     * @param {string} storeId
     * @param {string} postalCode
     * @param {number[]} entryNumberArray
     * @return {AxiosPromise}
     */
    updateDeliveryOption(
        type: string,
        guid: string,
        storeId: string,
        postalCode: string,
        entryNumberArray: number[],
    ): AxiosPromise {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { updateCartEndpoint },
        } = environment
        const endPoint = `${API_BASE_URL}${updateCartEndpoint}`
        const isSTH = type === FulfillmentMethods.STH || type === FulfillmentMethods.EXPRESS
        const defaultRequestBody = {
            deliveryMode: {
                code: type,
            },
            entryNumbers: entryNumberArray,
        }
        const requestBody = isSTH ? { ...defaultRequestBody, postalCode } : defaultRequestBody
        const guidAndStoreId = this.getGuidAndStoreId(guid, storeId)
        // Required only when changing from bopis to sth.
        const lang = `&lang=${CartService.language}`
        return CartService.apiMethod(apiMethods.PATCH, `${endPoint}${guidAndStoreId}${lang}`, requestBody)
    }

    /**
     * Merges guest cart with auth cart
     * @param {string} guestCartId
     * @param {string} authCartId
     * @param {string} selectedPreferredStore
     * @param {number[]} orderEntries
     * @param {boolean} isBuyNow
     * @return {AxiosPromise}
     */
    mergeCartItems(
        guestCartId: string,
        authCartId: string,
        selectedPreferredStore: string,
        orderEntries?: number[],
        isBuyNow?: boolean,
    ): AxiosPromise {
        const requestPayload = {
            oldCartId: guestCartId,
            mergeCartId: authCartId,
            orderEntries: orderEntries,
        }
        const {
            API_BASE_URL,
            API_ENDPOINTS: { mergeGuestAuthCart },
        } = environment
        const url = `${API_BASE_URL}${mergeGuestAuthCart}?storeId=${this.getStoreId(selectedPreferredStore)}&lang=${
            CartService.language
        }${isBuyNow ? '&isBuyNow=true' : ''}`
        return httpClient.apiPost(url, requestPayload)
    }

    /**
     * Updates user selected bulk delivery option to API
     * @param {string} guid
     * @param {UpdateDeliveryRequest} requestPayload
     * @return {AxiosPromise}
     */
    updateBulkDeliveryOption(guid: string, requestPayload: UpdateDeliveryRequest): AxiosPromise {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { updateBulkDeliveryOption },
        } = environment
        const endPoint = `${API_BASE_URL}${updateBulkDeliveryOption}`
        const availableGuid = this.getGUID(guid)

        return CartService.apiMethod(
            apiMethods.POST,
            `${endPoint}?bulkDeliveryOptionType=${String(
                requestPayload.bulkDeliveryOptionType,
            )}&guid=${availableGuid}&storeId=${requestPayload.storeId}&language=${CartService.language}`,
            {},
        )
    }

    /**
     * Function for change Reusable Bags opt-in/out
     * @param {boolean} type
     * @param {string} guid
     * @param {string} selectedPreferredStoreId
     * @return {AxiosPromise}
     */
    updateReusableBagsOptIn(type: boolean, guid: string, selectedPreferredStoreId: string): AxiosPromise {
        const url: URL = this.createReusableBagsOptInUrl(guid, selectedPreferredStoreId)

        const requestBody = {
            optIn: type,
        }

        return CartService.apiMethod(apiMethods.POST, url.toString(), requestBody)
    }

    /**
     * Function for add to cart bundle API call
     * @param {IAddToCartBundleRequest} requestData request body data
     * @returns {AxiosPromise} AxiosPromise
     */
    addToCartBundle(requestData: IAddToCartBundleRequest): AxiosPromise {
        const url: URL = this.createAddToCartBundleUrl(requestData.guid)

        const requestBody = { entryNumber: requestData.entryNumber, entryGroupNumber: requestData.entryGroupNumber }
        return CartService.apiMethod(apiMethods.POST, url.toString(), requestBody)
    }

    /**
     * Create add to cart bundle URL
     * @param {string} guid guid
     * @param {string} storeId store id
     * @returns {URL} add to cart bundle url
     */
    createAddToCartBundleUrl(guid: string, storeId?: string): URL {
        const { cartBundle } = environment.API_ENDPOINTS

        return new URL(
            `${environment.API_BASE_URL}${cartBundle}${this.getGuidAndStoreId(guid, storeId)}&lang=${
                CartService.language
            }`,
        )
    }

    /**
     * Create Reusable Bags url based on mock or cds
     * @param {string} guid
     * @param {string} selectedPreferredStoreId
     * @return {URL}
     */
    createReusableBagsOptInUrl(guid: string, selectedPreferredStoreId: string): URL {
        const lang = `&lang=${CartService.language}`
        return new URL(
            `${environment.API_BASE_URL}${
                environment.API_ENDPOINTS.reusableBags
            }?guid=${guid}${lang}&storeId=${this.getStoreId(selectedPreferredStoreId)}`,
        )
    }

    /**
     * Create Potential to Earn URL
     * @param {string} selectedPreferredStoreId
     * @returns {CartPotentialToEarnDTO}
     */
    createCartPTEUrl(selectedPreferredStoreId: string): Promise<CartPotentialToEarnDTO> {
        const url: URL = new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cartPotentialToEarn}?storeId=${selectedPreferredStoreId}`,
        )
        return httpClient
            .apiGet<CartPotentialToEarnDTO>(url.toString(), {})
            .then(cartPTEResponse => cartPTEResponse.data)
    }
}

// Creating a singleton instance of service
const cartService = new CartService()

export { cartService }
export default cartService
