import spaAnalytics from '../analytics/components/spaAnalytics'
import { analyticsAction, analyticsAttributes, analyticsLinkOption, analyticsStep } from '../globalConstants'

const {
    eventParameters: { action },
} = analyticsAttributes

const { continueToTriangleRewardsRegistration, submitTriangleRewardsRegistration } = analyticsAction

/**
 * SSO login analytics
 * @param  {boolean} isSuccess - true if user becomes authorized
 * @returns {void}
 */
export const ssoLoginAnalyticsEvent = (isSuccess: boolean): void => {
    spaAnalytics.setSSOLoginAnalytics(isSuccess)
}

/**
 * User Login From CLP analytics
 * @param  {boolean} isSuccess - true if user becomes authorized
 * @returns {void}
 */
export const setLoginFromCLPAnalytics = (isSuccess: boolean): void => {
    spaAnalytics.setLoginFromCLPAnalytics(isSuccess)
}

/**
 * Login was initialized automatically analytics
 * @returns {void}
 */
export const setAutoRedirectToCLPAnalytics = (): void => {
    spaAnalytics.setRedirectToCLPAnalytics(action.automaticRedirectToCLP)
}

/**
 * Login was initialized manually by user analytics
 * @returns {void}
 */
export const setManualRedirectToCLPAnalytics = (): void => {
    spaAnalytics.setRedirectToCLPAnalytics(action.manualRedirectToCLP)
}

/**
 * Analytics for registration new rewards card initiated from OB or CRP
 * @param  {string} sourceUrl - the source URL
 * @param  {boolean} isFromCRP - true if initiated from CRP, false if from OB
 * @param  {boolean} [isSuccess] - true if registration was successful, false if has error, undefined if has no result
 * @returns {void}
 */
export const registrationNewCardAnalytics = (sourceUrl: string, isFromCRP: boolean, isSuccess?: boolean): void => {
    const actionData =
        isSuccess === undefined
            ? {
                  action: continueToTriangleRewardsRegistration,
                  actionResult: {},
                  step: analyticsStep.chooseLoyaltyOptionFromOb,
              }
            : {
                  action: submitTriangleRewardsRegistration,
                  actionResult: isSuccess ? { success: true } : { error: true },
                  step: isFromCRP ? analyticsStep.registerLoyaltyCardFromCRP : analyticsStep.registerLoyaltyCardFromOb,
              }

    spaAnalytics.setRewardsRegistrationAnalytics(
        sourceUrl,
        actionData.step,
        analyticsLinkOption.newLoyalty,
        actionData.action,
        actionData.actionResult,
    )
}

/**
 * Analytics for link rewards card initiated from CRP
 * @param  {string} sourceUrl - the source URL
 * @param  {boolean} [isSuccess] - true if registration was successful, false if has error
 * @returns {void}
 */
export const linkExistingCardFromCRPAnalytics = (sourceUrl: string, isSuccess?: boolean): void => {
    const actionResult = isSuccess ? { success: true } : { error: true }

    spaAnalytics.setRewardsRegistrationAnalytics(
        sourceUrl,
        analyticsStep.registerLoyaltyCardFromCRP,
        analyticsLinkOption.linkLoyalty,
        submitTriangleRewardsRegistration,
        actionResult,
    )
}
